import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import routes from './route'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import axios from 'axios'
// import VueFbCustomerChat from 'vue-fb-customer-chat'
import VueSocialSharing from 'vue-social-sharing'
Vue.config.productionTip = false
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret,faTruckMoving,faArrowUp,faStoreAlt,faPeopleCarry,faGasPump,faPumpSoap } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faUserSecret,faTruckMoving,faStoreAlt,faArrowUp,faPeopleCarry,faGasPump,faPumpSoap)
Vue.component('font-awesome-icon', FontAwesomeIcon)
require('summernote/dist/summernote-bs4.js');
Vue.use(VueSocialSharing);
import * as VueGoogleMaps from 'vue2-google-maps'
import BackToTop from 'vue-backtotop'
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBx0DJKbn3HAf1P0TXh8pDl0QER9bZb7EM',
    libraries: 'places,drawing,visualization',
  },
});

// Vue.use(VueFbCustomerChat, {
//   page_id: "873499322765649", //  change 'null' to your Facebook Page ID,
//   theme_color: '#333333', // theme color in HEX
//   locale: 'en_US', // default 'en_US'
// })

Vue.use(BackToTop)
import vSelect from 'vue-select';
Vue.component('v-select', vSelect);
Vue.use(VueRouter);
axios.defaults.baseURL ="https://apidistrico.districom-ci.net/public/oauth/api/interface";
if(localStorage.getItem('districosite__api__Oauth')){
  axios.defaults.headers = {
      "Authorization" : "Bearer "+ JSON.parse(localStorage.getItem('districosite__api__Oauth')).token,
  }
}

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response.status == 401){
    setTimeout(()=>{
      localStorage.removeItem('districosite__api__Oauth');
      location.reload(true);
    },1000);
  }
  return Promise.reject(error);
});
const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior () {
      return { x: 0, y: 0 }
    }  
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')