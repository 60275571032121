<template>
  <div style="background:#eee">
      <div class="box-img-flyer">
        <img src="../assets/news.jpg" alt="">
        <div class="absolute-title text-white">
          <div style="width:100%;height:100%;">
                <h1 class="pt-5 font-weight-bold">L'actualité de DISTRICOM</h1>
          </div>
        </div>
       </div>
       <!-- <div class="box-img-flyer" style="background: url('../assets/news.jpg')">
        <div class="box-img-flyer" style="height:100%">
          <div class="container-table" style="height:100% !important">
              <div class="box-cell">
                  <div class="text-white text-center">
                       <h1>L'actualité de DISTRICOM</h1>
                  </div>
            </div>
          </div>
      </div>
       </div> -->
        <div class="container mt-5 pb-5">
            <h1 class="text-orange">Parcourez nos articles !</h1>
            <p>Soyez les premiers à être informés, prenez de l'avance en restant connectés à nos articles.</p>
            <div class="alert alert-success mt-3" v-if="article">
                <div class="d-flex">
                    <div class="spinner-border mr-5" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>

                    <span>Patientez pendant le chargement de nos articles ...</span>
                </div>
            </div>
            <div class="alert alert-danger" v-if="articlebug">
                Nous n'arrivons pas à nous connecter au serveur, merci de verifier votre connexion internet .. 
            </div>

            <div class="alert alert-info" v-if="data.length == 0">
                Aucun article disponible ...
            </div>


            <div class="row mt-5">
                <div class="col-sm-6" v-for="(item) in data" :key="item.actualite_id">
                    <router-link class="news" :to="'actualite/post/article/'+item.actualite_id+'/'+replace(item.actualite_title)">
                        <div class="cadre bg-white p-3 m-3">
                            <div style="width:100%;height:300px">
                                <img style="object-fit:cover;width:100%;height:100%" :src="'https://apidistrico.districom-ci.net/public/picture/show/'+item.actualite_image" alt="">
                            </div>
                            <h6 class="mb-3 mt-3 text-ellipsis title">{{item.actualite_title}}</h6>
                            <p class="text-black text text-wrapp" v-html="item.actualite_description"></p>
                            <p class="m-0">Publié le : {{item.created_at}}</p>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import backendService from '../backend.service';
export default {
data(){
      return {
          data : [],
          article : false,
          articlebug : false,
      }
    },
    mounted(){
            this.article = true;
            this.articlebug = false;
            backendService.actualite_public_get().then((response)=>{
                if(response.status == 200){
                    this.data = response.data.data;
                    this.article = false;
                    this.articlebug = false;
                }
            }).catch(()=>{
                this.article = false;
                this.articlebug = true;
            });
    },
    methods : {
        replace(info){
            let r = info.replace(/ /g,'-');
            r = info.replace(new RegExp("[^(a-zA-Zéèêà)]", "g"),'-').toLowerCase();
            // return r.replace(new RegExp("[^(a-zA-Zé\è\ê\à)]", "g"),'-').toLowerCase();
            return r;
        }
    }
}
</script>

<style scoped lang="scss">
    .news {
        text-decoration: none !important;
        color : #000 !important;
        &:hover{
            .title{
                    text-decoration: underline !important;
            }
        }
        .title{
            color : #000 !important;
        }
        .text{
           color : #aaa !important;

        }
    }
    .cadre{
        &:hover{
            transform: scale(1.01);
            transition: all 0.5s ease-out;
            box-shadow: 0 1rem 0.5rem rgba(0, 0, 0, 0.075) !important;
        }
    }
</style>