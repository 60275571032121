<template>
  <div style="font-size:16px">
      <h4 class="text-blue"><b>Développement et soutien aux communautés locales</b></h4>
      <p>Nous sommes engagés depuis plusieurs années aux côtés des populations dans le but de participer au développement local des collectivités à travers notre fondation (Fondation DISTRICOM).</p>
      <p><a href="https://fondation.districom.ci" target="_blank"><b>Visitez le site de la Fondation DISTRICOM</b></a></p>
      <h4 class="text-blue mt-5"><b>Hygiène, santé et sécurité</b></h4>
      <p>Soucieuse du bien-être de son personnel, de ses partenaires, de ses clients et de la protection de l’environnement, DISTRICOM se dote d’une politique d’hygiène, de sécurité, de santé et d’environnement au travail pour son activité dans le secteur de l’hydrocarbure.</p>
      <p>Notre objectif est de gagner la conﬁance de nos clients, de nos actionnaires et partenaires ainsi que du grand public et de contribuer à la protection de l’environnement et au développement durable.</p>
      <p>Dans cette optique, nous nous engageons à : </p>
      <p>
        <ul>
          <li>Poursuivre notre but tout en protégeant notre personnel, nos partenaires et l’environnement</li>
          <li>Utiliser le matériel adapté pour livrer nos produits et assurer nos services</li>
          <li>Publier un rapport de nos performances en matière d’hygiène, de sécurité, de santé et environnement</li>
          <li>Jouer un rôle majeur dans la promotion des meilleures pratiques dans notre secteur d’activité</li>
          <li>Promouvoir une culture amenant tous les collaborateurs, le personnel, les clients de DISTRICOM à adhérer à notre politique RSE (Responsabilité Sociétale des Entreprises).</li>
        </ul>
      </p>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>