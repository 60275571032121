<template>
  <div style="font-size:16px">
      <h4 class="text-blue"><b>Distribution</b></h4>
      <p>Depuis plus d’une vingtaine d’année, DISTRICOM intervient dans la commercialisation de produits pétroliers et gaziers sur toute l’étendue du territoire national.</p>
      <h4 class="text-blue mt-5"><b>Transport de produits pétroliers et gaziers</b></h4>
      <p>Nous intervenons aussi dans le transport de produits pétroliers et gaziers pour le compte d’autres entreprises. Nous disposons d’un important parc automobile qui nous permet de satisfaire chaque jour, nos clients professionnels.</p>
      <h4 class="text-blue mt-5"><b>BTP</b></h4>
      <p><b>DISTRICOM</b> exerce également dans le domaine du bâtiment pour mener à bien tous vos projets de construction, rénovation, extension de stations-services et autres types de bâtiment. A ce titre, nous intervenons dans l’installation d’équipements de stockage et de distribution (cuve à gaz, citerne à carburant) sur vos différents sites exploitation.</p>
      <p>En plus, <b>DISTRICOM</b> opère dans l’installation d’équipements de stockage et de distribution (cuve à gaz, citerne à carburant) sur vos différents sites d’exploitation.</p>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>