<template>
    <div class="h-full d-flex">
            <div class="h-full-w400px">
                <div class="h-full-h85 p-2">
                    <p class="text-blue font-weight-bold">Publier un nouvel article</p>        
                    <input type="file" class="form-control" id="front_image" aria-describedby="inputGroupFileAddon01">
                    <input type="text" v-model="actualite_title" class="form-control mt-3 mb-3" placeholder="Titre de l'article ...">
                    <textarea class="form-control mt-3 mb-3" v-model="actualite_description" placeholder="Description de l'article ..."></textarea>
                    <textarea id="editorhtml" class="mt-3"></textarea>
                </div>
                <div class="h-full-h15 border-top p-2"> 
                  <button @click="post" class="btn btn-site font-weight-bold btn-sm">Publier maintenant
                    <div v-if="loading" class="spinner-border text-light float-right mt-2" style="height:20px;width:20px" role="status">
                         <span class="sr-only">Loading...</span>
                    </div>
                  </button>
                </div>
            </div>
            <div class="h-full-rw400px border-left p-3">
                <div class="d-flex justify-content-between">
                  <h1 class="text-blue">Liste des articles publiés</h1>
                  <div v-if="loadingget" class="spinner-border text-black mt-2" style="height:40px;width:40px" role="status">
                          <span class="sr-only">Loading...</span>
                  </div>
                </div>
                <table class="table mt-3 table-bordered table-hover">
                        <thead>
                        <tr>
                            <th>Image</th>
                            <th>Info</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                                <tr v-for="item in actualites" :key="item.activity_id">
                                    <td>
                                       <div style="width:100px;height:100px">
                                          <img :src="'https://apidistrico.districom-ci.net/public/picture/show/'+item.actualite_image" alt="" style="object-fit:cover;width:100%;height:100%">
                                       </div>
                                    </td>
                                     <td>
                                        <h6>{{item.actualite_title}}</h6>
                                        <p>{{item.actualite_description}}</p>
                                        <p>Publié le : {{item.created_at}}</p>
                                    </td>
                                    <td>
                                        <button @click="deleted(item.actualite_id)" class="btn btn-danger font-weight-bold btn-sm">Supprimer
                                          <div v-if="loadingdelete" class="spinner-border text-light float-right mt-2" style="height:20px;width:20px" role="status">
                                              <span class="sr-only">Loading...</span>
                                          </div>
                                        </button>
                                    </td>
                                </tr>
                        </tbody>
                    </table>
            </div>
    </div>
</template>

<script>
import $ from 'jquery';
import backendService from '../../../backend.service';
// import backendService from '../../../backend.service'
export default {
    data(){
      return {
          actualite_title : null,
          actualite_content : null,
          actualite_description : null,
          loading : false,
          loadingdelete : false,
          loadingget : false,
          actualites : []
      }
    },
    mounted(){
      $('#editorhtml').summernote({
        placeholder: 'Saisissez ...',
        tabsize: 2,
        height: 100,
        toolbar: [
          ['style', ['style']],
          ['font', ['fontname','bold', 'underline', 'clear','strikethrough', 'superscript', 'subscript']],
          ['color', ['color']],
          ['fontsize', ['fontsize']],
          ['para', ['ul', 'ol', 'paragraph']],
          ['table', ['table']],
          ['insert', ['link', 'picture','video','hr']],
          ['view', ['fullscreen','codeview']]
        ]
      });

      this.get()
    },
    methods:{
        get(){
          this.loadingget = true
          backendService.actualite_public_get().then((response)=>{
              this.actualites = response.data.data;
              this.loadingget = false
          }).catch(()=>{this.loadingget = false})
        },
        deleted(id){
          this.loadingget = true
          backendService.actualite_delete(id).then(()=>{
            this.get()
            this.loadingget = false
          }).catch(()=>{this.loadingget = false})
        },
        post(){
              this.loading = true;
              let me = this;
              var fd = new FormData();
              var files = $('#front_image')[0].files[0];
              fd.append('actualite_title',me.actualite_title);
              fd.append('actualite_description',me.actualite_description);
              fd.append('actualite_content',$('#editorhtml').summernote('code'));
              fd.append('actualite_file',files);
              $.ajax({
                  url: 'https://apidistrico.districom-ci.net/public/oauth/api/interface/actualite/create',
                  type: 'post',
                  data: fd,
                  contentType: false,
                  processData: false,
                  headers:{
                      "Authorization" : "Bearer "+ JSON.parse(localStorage.getItem('districosite__api__Oauth')).token,
                  },
                  success: function(response){
                      me.loading = false;
                      if(response.status == 200){
                        alert('article publié avec succès !');
                        me.actualites = response.data;
                      }
                  },
              });
        }
    }
}
</script>

<style>

</style>