<template>
    <div class="slide">
        <!-- <div class="absolute-layer">
            <div class="container-table">
                <div class="box-cell">
                        <div class="col-sm-6 offset-sm-3 text-white text-center" >
                                <h6>Recherchez une Station ou un point de vente de GAZ</h6>
                                <div class="input-group">
                                    <v-select v-model="search" class="style-chooser styleh-chooser" style="min-width:400px" placeholder="recherchez une ville" :options="cities"></v-select>
                                    <div class="input-group-prepend">
                                        <button @click="goingto" class="btn btn-site font-weight-bold" type="button" id="button-addon1" style="border-top-left-radius:0 !important;border-bottom-left-radius:0 !important">CONTINUER</button>
                                    </div>
                                </div>
                        </div>
                </div>
            </div>
        </div> -->
        <!-- <swiper class="swiper" :options="swiperOption">
            <swiper-slide class="slide-1">
                <div class="title font-weight-bold text-center p-3" data-swiper-parallax="-100">
                    <h1 class="mt-5 text-white" style="font-size:100px;text-shadow:0 0 2px #000">Bienvenue chez <span class="text-yellow">DISTRICOM</span> </h1>
                    <h1 class="text-white" style="text-shadow:0 0 2px #000">Le <span class="text-yellow">champion de la DISTRIBUTION </span> en Côte d'Ivoire</h1>
                </div>
            </swiper-slide>
            <swiper-slide class="slide-2">
                <div class="title font-weight-bold text-center p-3" data-swiper-parallax="-100">
                    <h1 class="mt-5 text-white" style="font-size:100px;text-shadow:0 0 2px #000"><span class="text-yellow">DISTRICOM OIL</span> </h1>
                    <h1 class="text-white" style="text-shadow:0 0 2px #000">L'assurance d'un carburant de qualité</h1>
                </div>
            </swiper-slide>
            <swiper-slide class="slide-3" data-swiper-parallax="-100"></swiper-slide>
            <swiper-slide class="slide-4">
                <div class="title font-weight-bold text-center p-3" data-swiper-parallax="-100">
                    <h1 class="mt-5 text-white" style="font-size:100px;text-shadow:0 0 2px #000">DANS NOS<span class="text-yellow"> STATIONS</span> </h1>
                    <h1 class="text-white" style="text-shadow:0 0 2px #000">1L acheté = 1 Franc offert aux orphelins</h1>
                </div>
            </swiper-slide>
            <swiper-slide class="slide-5"></swiper-slide>
            <swiper-slide class="slide-6" data-swiper-parallax="-100"></swiper-slide>
            <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
            <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
            <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
        </swiper> -->
        <div id="carouselExampleCaptions" style="height:auto" class="carousel slide" data-ride="carousel">
                <ol class="carousel-indicators">
                    <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
                    <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
                    <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
                    <li data-target="#carouselExampleCaptions" data-slide-to="3"></li>
                    <!-- <li data-target="#carouselExampleCaptions" data-slide-to="3"></li>
                    <li data-target="#carouselExampleCaptions" data-slide-to="4"></li>
                    <li data-target="#carouselExampleCaptions" data-slide-to="5"></li> -->
                    <!-- <li data-target="#carouselExampleCaptions" data-slide-to="5"></li> -->
                </ol>
                <div class="carousel-inner slide-img">
                    <div class="carousel-item active">
                        <img src="../../assets/slider/new_slide_1.jpg" class="d-block" alt="...">
                    <!-- <div class="carousel-captionfont-weight-bold">
                        <div class="title font-weight-bold text-center p-3" data-swiper-parallax="-100">
                            <h1 class="mt-5 text-white" style="font-size:100px;text-shadow:0 0 2px #000">Bienvenue chez <span class="text-yellow">DISTRICOM</span> </h1>
                            <h1 class="text-white" style="text-shadow:0 0 2px #000">Le <span class="text-yellow">champion de la DISTRIBUTION </span> en Côte d'Ivoire</h1>
                        </div>
                    </div> -->
                    </div>
                    <div class="carousel-item">
                        <img src="../../assets/slider/new_slide_2.jpg" class="d-block w-100" alt="...">
                    <!-- <div class="carousel-captionn d-none d-md-block font-weight-bold">
                        <div class="title font-weight-bold text-center p-3" data-swiper-parallax="-100">
                                <h1 class="mt-5 text-white" style="font-size:100px;text-shadow:0 0 2px #000"><span class="text-yellow">DISTRICOM OIL</span> </h1>
                                <h1 class="text-white" style="text-shadow:0 0 2px #000">L'assurance d'un carburant de qualité</h1>
                        </div>
                    </div> -->
                    </div>
                    
                    <div class="carousel-item">
                        <img src="../../assets/slider/new_slide_3.jpg" class="d-block w-100" alt="...">
                    </div>

                    <div class="carousel-item">
                        <img src="../../assets/slider/new_slide_4.jpg" class="d-block w-100" alt="...">
                    </div>
                    <!-- <div class="carousel-item">
                    <img src="../../assets/slider/slide_3.jpg" class="d-block w-100" alt="...">
                     <div class="carousel-captionn d-none d-md-block font-weight-bold">
                        <div class="title font-weight-bold text-center p-3" data-swiper-parallax="-100">
                            <h1 class="mt-5 text-white" style="font-size:100px;text-shadow:0 0 2px #000">DANS NOS<span class="text-yellow"> STATIONS</span> </h1>
                            <h1 class="text-white" style="text-shadow:0 0 2px #000">1L acheté = 1 Franc offert aux orphelins</h1>
                        </div>
                    </div>
                    </div> -->
                     <!-- <div class="carousel-item">
                    <img src="../../assets/slider/slide_5.jpg" class="d-block w-100" alt="...">
                        
                    </div>
                     <div class="carousel-item">
                        <img src="../../assets/slider/slide_1.jpg" class="d-block" alt="...">
                    </div> -->
                     <!-- <div class="carousel-item">
                    <img src="../../assets/slider/slide_6.jpg" class="d-block w-100" alt="...">

                    </div> -->
                </div>
                <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
        </div> 
    </div>
</template>
<script>
import backendService from '../../backend.service';
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
// import 'swiper/swiper.scss'
export default {
  name: 'Slide',
  props: {},
  components: {
    //   Swiper,
    //   SwiperSlide
    },
  data(){
      return {
            cities : [],
            search : null,
            swiperOption: {
                spaceBetween: 0,
                effect: 'fade',
                loop : true,
                autoplay: {
                        delay: 3000,
                    },
                    speed: 500,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
                }
      }
  },
  mounted(){
    backendService.station_get().then((response)=>{
        response.data.data.city.forEach(element => {
            this.cities.push(element.ville);
        });
    });
  },
  methods : {
      goingto : function(){
        this.$router.push({
            name : 'stations' , params : {
            city : this.search
        }});
        // location.reload(true);
      }
  }
}
</script>

<style scoped lang="scss">
    .slide{
        /* height: 500px; */
        position: relative;
    }

    // .slide-img{
    //     img{
    //         max-width: 100%;
    //         height: auto;
    //     }
    // }

    .slide-img {
        background: #000;
        text-align: center;
        img{
            // height: 500px;
            max-width: 100%;
            height: auto;

            // object-fit: contain;
        }
    }

    .title{
        background:rgba($color: #000000, $alpha: 0.3);
        position: absolute;
        bottom:0;
        left:0;
        right:0
    }

    .swiper {
    height: calc(100vh - 110px);

    .swiper-slide {
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center top; 

      &.slide-1 {
        background-image:url('../../assets/slider/bg_01.jpg')
      }
      &.slide-2 {
        background-image:url('../../assets/slider/slide2.jpg')
      }
      &.slide-3 {
        background-image:url('../../assets/slider/slide3.jpg')
      }
      &.slide-4 {
        background-image:url('../../assets/slider/slide4.jpg')
      }
      &.slide-5 {
        background-image:url('../../assets/slider/slide5.jpg')
      }
      &.slide-6 {
        background-image:url('../../assets/slider/slide6.jpg')
      }
    }
  }
</style>