<template>
  <div class="container mb-5">
        <div class="col-sm-12">
            <div class="row">
                    <div class="col-sm-12">
                        <h1 class="mt-3">Contactez-nous ! </h1>
                        <p class="text-blue">Nous serons ravis de répondre à vos besoins </p>
                    </div>

                    <div class="col-sm-12 mt-4">
                        <div class="row">
                            <div class="col-sm-6">
                                    <p>Quartier Dioulakro, Sur la route d'Oumé Yamoussoukro, Cote d'Ivoire</p>
                                    <p>B.P 2487 Yamoussoukro</p>
                                    <p>Tél: +225 05 46 10 00 10 / +225 25 300 00 300</p>
                                    <p>Email: info@districom.ci</p>
                                    <hr>
                                    <p class="font-weight-bold text-blue">DIRECTION COMMERCIALE</p>
                                    <p>Email : sales@districom.ci</p>
                                    <p>Tél : +225 05 06 18 00 91 </p>
                            </div>
                            <div class="col-sm-6">
                                <h4 class="text-blue">Ecrivez-nous ! </h4>
                                <input v-model="name" type="text" class="form-control" placeholder="Nom & prénoms">
                                <input v-model="email" type="email" class="form-control mt-3" placeholder="Adresse-email">
                                <input v-model="subject" type="text" class="form-control mt-3" placeholder="Objet">
                                <textarea v-model="message" name="" id="" cols="30" class="form-control mt-3" rows="6" placeholder="Message"></textarea>
                                <div v-if="sending" class="alert alert-success mt-3">
                                        Votre message a été envoyé avec success !
                                </div>
                                <button @click="contactinfo" :disabled="!name || !email || !message" class="btn btn-site w-100 btn-lg font-weight-bold mt-3" style="width:auto"> Envoyer le message
                                    <div v-if="loading" class="spinner-border text-light float-right" style="height:20px;width:20px" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
            </div>
        </div>

  </div>
</template>

<script>
import backendService from '../backend.service';
export default {
    data(){
        return {
            name : null,
            email : null,
            message : null,
            subject : null,
            loading : false,
            sending : false
        }
    },
    methods : {
        contactinfo(){
            this.loading = true;
            backendService.contactinfo({
                email : this.email,
                name : this.name,
                message : this.message,
                subject : this.subject
            }).then((response)=>{   
                    this.loading = false;
                    if(response.data.status == 200)
                        this.sending = true;
                        this.name = null;
                        this.subject = null;
                        this.message = null;
            }).catch(()=>{
                this.loading = false;
            })
        }
    }
}
</script>

<style>

</style>