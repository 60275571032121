<template>
  <div>
      <div class="box-img-flyer" style="height:350px">
          <div class="container-table" style="background:#0b2239;height:100% !important">
              <div class="box-cell">
                  <div class="text-white text-center">
                      <h1>Réjoignez notre équipe !</h1>
                      <button class="btn btn-site font-weight-bold" data-toggle="modal" data-target="#candidature" style="width:auto">
                          Candidature spontanée
                      </button>
                  </div>
            </div>
          </div>
      </div>


    <div class="col-sm-8 offset-sm-2 mt-5 mb-5" v-if="offer">
                <h1 class="text-left">Liste des offres</h1>
                <p>Chez DISTRICOM, nous sommes engagés dans le développement continu de nos employés. Nous comprenons qu’en tant qu’entreprise, notre performance est étroitement liée à celle de nos employés. C'est pourquoi nous recherchons activement des collaborateurs ayant une rare combinaison de compétences, d'expérience, de responsabilités, d'engagement et d'ambition qui contribueront au succès de DISTRICOM, à l'établissement de sa réputation et en feront un employeur privilégié.</p>
                <p class="font-weight-bold">{{offer.length}} nouvelle(s) offre(s) emploi/stage</p>
                  <ul class="list-unstyled mt-5">
                      <li class="media" v-for="item in offer" :key="item.id">
                        <div class="media-body">
                            <div class="row">
                              <div class="col-sm-8 border-bottom border-top p-3">
                                <p class="mt-0 mb-1 text-blue font-weight-bold h5">{{item.title}}</p>
                               {{item.description}}
                              </div>
                              <div class="col-sm-4 border-left border-bottom border-top text-center">
                                <router-link :to="'job/content/'+item.code" class="btn btn-site font-weight-bold mt-5" style="width:auto">
                                    Voir plus
                                </router-link>
                              </div>
                            </div>
                        </div>
                      </li>
                  </ul>
    </div>

      <!-- Modal -->
      <div class="modal fade" id="candidature" tabindex="-1" aria-labelledby="candidatureLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <p class="modal-title text-blue font-weight-bold" id="candidatureLabel"> Candidature spontanée</p>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <input type="text" v-model="post" class="form-control mt-2" placeholder="Poste souhaité">
                <input type="text"  v-model="name" class="form-control mt-4" placeholder="Nom & prénoms">
                <input type="number"  v-model="tel" class="form-control mt-4" placeholder="Numéro de téléphone">
                <input type="email"  v-model="email" class="form-control mt-4" placeholder="Email">
                <textarea name="" maxlength="400"  v-model="message" class="form-control mt-3" placeholder="Votre message" id="" cols="30" rows="6">

                </textarea>
                <p class="mb-0 mt-4">CV (PDF OBLIGATOIRE)</p>
                <input type="file" id="cv" class="form-control mb-5">
            </div>
            <div class="modal-footer">
              <button @click="application_sp" :disabled="!post || !name || !tel || !email || !message" class="btn btn-site font-weight-bold" style="width:auto">
                  ENVOYER
                  <div v-if="loading" class="spinner-border text-light float-right mt-2" style="height:20px;width:20px" role="status">
                         <span class="sr-only">Loading...</span>
                  </div>
              </button>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import $ from 'jquery';
import backendService from '../backend.service';
export default {
  data(){
    return {
      offer : [],
      post : null,
      name : null,
      tel : null,
      email : null,
      message : null,
      loading : false
    }
  },
  mounted(){
     backendService.job_public_get().then((response)=>{
            this.offer = response.data.data;
      }).catch(()=>{});
  },
  methods:{
    application_sp(){
              this.loading = true;
              let me = this;
              var fd = new FormData();
              var files = $('#cv')[0].files[0];
              fd.append('post',me.post);
              fd.append('name',me.name);
              fd.append('tel',me.tel);
              fd.append('email',me.email);
              fd.append('message',me.message);
              fd.append('attached',files);
              $.ajax({
                  url: 'https://apidistrico.districom-ci.net/public/application/spontaneous/create',
                  type: 'post',
                  data: fd,
                  contentType: false,
                  processData: false,
                  success: function(response){
                      me.loading = false;
                      if(response.status == 200){
                        alert('candidature envoyée avec succès !');
                        location.reload(true);
                      }
                  },
              });
    }
  }
}
</script>

<style scoped>

</style>