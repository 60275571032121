<template>
    <div class="bout pb-5" style="background:#dfe1ed">
        <div class="p-3 container">
            <div class="col-sm-12 text-center mt-5">
                <h3>DISTRICOM GAZ</h3>
                <p>Les prix des consignes</p>
            </div>

            <div class="col-sm-12">
                <div class="row">
                    <div class="col-sm-4 mb-3">
                            <div style="position:relative;background : #f7f7f7" class="w-100 card-bout text-center showonhover shadow-sm border">
                                    <p class="p-3 border-bottom font-weight-bold">Bouteille 6Kg</p>
                                    <img class="img-fluid" style="height:100px" src="../assets/b6.jpeg" alt="">
                                    <div class="p-1 bout-description">Bouteille économique</div>
                                    <p class="border-top m-0 p-3 font-weight-bold text-blue"> Prix de la consigne : 12 500 FCFA </p>
                                    <div class="floating-block">
                                         <img class="img-fluid" src="../assets/b6.jpeg" alt="">
                                    </div>
                            </div>                            
                    </div>
                    <div class="col-sm-4 mb-3">
                             <div style="position:relative;background : #f7f7f7" class="w-100 card-bout text-center showonhover shadow-sm border">
                                    <p class="p-3 border-bottom font-weight-bold">Bouteille 12.5Kg</p>
                                    <img class="img-fluid" style="height:100px" src="../assets/b12.jpeg" alt="">
                                    <div class="p-1 bout-description">Bouteille économique aux usages multiples</div>
                                    <p class="border-top m-0 p-3 font-weight-bold text-blue"> Prix de la consigne : 23 000 FCFA </p>
                                    <div class="floating-block">
                                         <img class="img-fluid" src="../assets/b12.jpeg" alt="">
                                    </div>
                            </div>
                    </div>
                    <div class="col-sm-4 mb-3">
                             <div style="position:relative;background : #f7f7f7" class="w-100 card-bout text-center showonhover shadow-sm border">
                                    <p class="p-3 border-bottom font-weight-bold">Bouteille 38Kg</p>
                                    <img class="img-fluid" style="height:100px" src="../assets/b38.jpeg" alt="">
                                    <div class="p-1 bout-description">Bouteille pour les consommations importantes et les utilisations professionnelles</div>
                                    <p class="border-top m-0 p-3 font-weight-bold text-blue"> Prix de la consigne : 50 000 FCFA </p>
                                    <div class="floating-block">
                                         <img class="img-fluid" src="../assets/b38.jpeg" alt="">
                                    </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
    .floating-block{
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background: #f7f7f7;
        overflow: hidden;
        width: 100%;
        height:0;
        transition: .2s ease;
    }

    .bout-description{
        height: 120px;
        font-size:14px;
        display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }
    

    .showonhover:hover > .floating-block{
        top: 0;
        height: 100%;
    }
</style>