<template>
    <div>
        <!-- <div class="box-img-flyer">
            <img src="../../../assets/banner.jpg" alt="">
            <div class="absolute-title text-white">
            <div style="width:100%;height:100%;background:rgba(0,0,0,0.6)">
                    <h1 class="p-3">DISTRICOM OIL</h1>
            </div>
            </div>
        </div> -->
        <div class="p-0" style="text-align : justify">
            <p class="border-bottom"><img class="img-fluid" src="../../../assets/banner.jpg" alt=""></p>
            <div class="p-3">
                <p class="mt-3"><b>DISTRICOM</b> est la branche qui est chargée de la distribution de gaz butane.</p>
                <p>Nous distribuons du gaz butane pour les ménages et commerces sous trois (3) formats :</p>
                <p class="pl-4">     
                        -	<b>La bouteille B6 : </b> La bouteille économique pour les usages occasionnels <br>
                        -	<b>La bouteille B12 : </b> La bouteille B12 : La bouteille économique aux usages multiples pour une consommation moyenne <br>
                        -	<b>La bouteille B38 : </b> La bouteille B38 : La bouteille pour les consommations importantes et les utilisations professionnelles
                </p>
                <p>Nous disposons de nos propres centres emplisseurs et d’une flotte automobile importante pour assurer l’approvisionnement continu des nos différents points de vente.</p>
                <p>Le Gaz DISTRICOM est disponible dans toutes les stations-services DISTRICOM OIL 7j/7 et 24h/24.</p>
                <p>Nous fournissons également du gaz en vrac aux entreprises pour leurs besoins d’exploitation.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
name: 'Districomgaz',
}
</script>

<style>

</style>