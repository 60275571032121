<template>
  <div>
    <div class="box-img-flyer" style="height:auto !important">
        <div class="container-table" style="background:#33CCFF;height:100%">
            <div class="box-cell">
                <div class="text-white p-5 text-center">
                    <h3>{{html.title}}</h3>
                    <button class="btn btn-site font-weight-bold" data-toggle="modal" data-target="#candidature" style="width:auto">
                        Postuler à cette offre !
                    </button>
                </div>
            </div>
        </div>
    </div>

    
    <div class="col-sm-8 offset-sm-2 mt-5 mb-5" v-if="html">
            <p class="font-weight-bold"><i> Postuler avant le : {{html.validate | moment}}</i></p>
            <div v-html="html.content"></div>
            <div>
                <hr>
                <button class="btn btn-site font-weight-bold" data-toggle="modal" data-target="#candidature" style="width:auto">
                        Postuler à cette offre !
                    </button>
            </div>
    </div>
    <!-- Modal -->
      <div class="modal fade" id="candidature" tabindex="-1" aria-labelledby="candidatureLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <p class="modal-title text-blue font-weight-bold" id="candidatureLabel"> Candidature</p>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <p class="font-weight-bold">Code offre : {{html.code}}</p>
                <input type="text"  v-model="name" class="form-control mt-4" placeholder="Nom & prénoms">
                <input type="number"  v-model="tel" class="form-control mt-4" placeholder="Numéro de téléphone">
                <input type="email"  v-model="email" class="form-control mt-4" placeholder="Email">
                <p class="mb-0 mt-4">CV (PDF OBLIGATOIRE)</p>
                <input type="file" id="cv" class="form-control mb-5">
            </div>
            <div class="modal-footer">
              <button @click="application" :disabled="!name || !tel || !email" class="btn btn-site font-weight-bold" style="width:auto">
                  ENVOYER VOTRE CANDIDATURE
                  <div v-if="loading" class="spinner-border text-light float-right mt-2" style="height:20px;width:20px" role="status">
                         <span class="sr-only">Loading...</span>
                  </div>
              </button>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import backendService from '../backend.service';
import moment from 'moment';
import $ from 'jquery';
export default {
  data(){
    return {
      html : {},
      name : null,
      tel : null,
      email : null,
      loading : false
    }
  },
  mounted(){
     backendService.job_public_get(this.$route.params.code).then((response)=>{
            this.html = response.data.data;
            if(!response.data.data)
                this.$router.replace({name : 'job'});
      }).catch(()=>{});
  },
  filters : {
    moment: function (date) {
      return moment(date).format('DD/MM/YYYY');
    }
  },
  methods:{
    application(){
              this.loading = true;
              let me = this;
              var fd = new FormData();
              var files = $('#cv')[0].files[0];
              fd.append('offer',me.html.code);
              fd.append('name',me.name);
              fd.append('tel',me.tel);
              fd.append('email',me.email);
              fd.append('attached',files);
              $.ajax({
                  url: 'https://apidistrico.districom-ci.net/public/application/offer/create',
                  type: 'post',
                  data: fd,
                  contentType: false,
                  processData: false,
                  success: function(response){
                      me.loading = false;
                      if(response.status == 200){
                        alert('candidature envoyée avec succès !');
                        location.reload(true);
                      }
                  },
              });
    }
  }
}
</script>

<style>

</style>