<template>
        <div class="h-full d-flex">
            <div class="h-full-w400px">
                <div class="h-full-h85 p-4">
                        <p class="text-blue font-weight-bold">Ajouter une actualité</p>
                        <textarea  cols="30" rows="10" class="mt-3 form-control" v-model="news_message" placeholder="Rediger la new ici ..." ></textarea>
                </div>
                <div class="h-full-h15 border-top p-2">
                    <button @click="create" :disabled="!news_message" type="button" class="btn btn-md w-100 btn-site font-weight-bold">Publier
                         <div v-if="loading" class="spinner-border text-light float-right" style="height:20px;width:20px" role="status">
                                    <span class="sr-only">Loading...</span>
                         </div>
                    </button>
                </div>
            </div>
            <div class="h-full-rw400px border-left p-3">
                    <h1 class="text-blue">Liste des news</h1>
                     <table class="table mt-3 table-bordered table-hover">
                        <thead>
                        <tr>
                            <th>News</th>
                            <th>Date</th>
                        </tr>
                        </thead>
                        <tbody>
                                <tr v-for="item in news.list" :key="item.id">
                                    <td>
                                        {{item.news}}
                                    </td>
                                    <td>
                                        {{item.created_at}}
                                    </td>
                                </tr>
                        </tbody>
                    </table>
            </div>
        </div>
</template>

<script>
import backendService from '../../../backend.service'
export default {
    data(){
        return {
            loading : false,
            news : [],
            news_message : null
        }
    },
    mounted(){
            backendService.news_get().then((response)=>{
                    this.news = response.data.data;
            }).catch(()=>{})
    },
    methods : {
        create(){
            this.loading = true;
            backendService.news_create({
                news : this.news_message,
            }).then((response)=>{   
                    this.loading = false;
                    this.news = response.data.data;
            }).catch(()=>{
                this.loading = false;
            })
        }
    }
}
</script>

<style>

</style>