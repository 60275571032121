<template>
          <div class="mt-5">
          <div class="col-sm-12">
               <div class="dg">
                    <div class="col-sm-12 p-0">
                            <div class="row">
                                <div class="col-sm-12">
                                    <img src="../../assets/dg.jpg" class="img-fluid w-100 h-20" alt="">
                                    <div class="box p-4">
                                        <div class="arrow-l"></div>
                                        <h3 class="mb-3">Mot du Directeur Général</h3> 
                                        <div>
                                            <p >Chers amis et partenaires, soyez la bienvenue sur le site de la société de distribution et de commerce DISTRICOM.</p>
                                            <p>DISTRICOM est une entreprise ivoirienne qui compte aujourd’hui dans le domaine des produits pétroliers en Cote d’Ivoire. Avec un réseau de stations-service en pleine expansion et deux centres emplisseurs de gaz, DISTRICOM assure la distribution régulière de produits pétroliers de qualité pour combler tous vos besoins énergétiques que vous soyez particuliers ou professionnels. </p> 
                                            <p class="m-0">DISTRICOM est aussi l’entreprise citoyenne par excellence avec une politique sociale reconnue qui se traduit à travers le don de 1 FCFA par litre aux orphelins à travers la fondation DISTRICOM.</p>
                                            <p>Vous trouverez sur ce site, des informations utiles sur nos produits et services ainsi que l'impact de nos activités sociales sur les communautés.</p>
                                            <p class="m-0"><b> <i> Hussein ATTAR</i></b> </p>
                                            <p class="m-0"><b> <i>    Directeur Général</i></b></p>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="col-sm-12 mt-3">
                                    <img src="../../assets/equipe_plan-de-travail2.jpg" class="img-fluid w-100" alt="">
                                    <div class="box p-4 box-talking-team" style="color:#000;background:#dfe1ed">
                                        <div class="arrow-r"></div>
                                        <h3 class="mb-3">Notre équipe</h3> 
                                        <p>
                                            Notre équipe de professionnels expérimentés et motivés, travaille sans relâche pour répondre à tous vos besoins. Les stations-services DISTRICOM sont ouvertes 24h/24 et 7j/7 dans le seul but de vous satisfaire à tout moment.
                                        </p> 
                                    </div>
                                </div>
                            </div>
                    </div>
               </div>
          </div>
          </div>
          
</template>
<script>
// import Swiper from 'swiper';
export default {
  name: 'Dgmobile',
  mixins : [],
  props: {},
   data(){
        return{
            location : location.href
        }
    },
  mounted() {

  }
}
</script>

<style scoped>
    .box{
        height: auto;
        width: 100%;
        background: #FFFF00;
        color:#000;
        position: relative;
    }

    .box:hover{
        cursor: pointer;
    }
</style>