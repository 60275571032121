<template>
  <div>
    <div class="">
        <img src="../../../assets/districomoil.jpg" style="height:200px;width:100%" alt="">
        <div class="absolute-title text-white">
          <div style="width:100%;height:100%;background:rgba(0,0,0,0.6)">
                <h5 class="p-3">DISTRICOM OIL</h5>
          </div>
        </div>
    </div>
    <div class="col-sm-12 p-0" style="text-align : justify">
        <p>DISTRICOM OIL est la filiale du groupe DISTRICOM dont l’activité est principalement axée sur la distribution et le transport de produits pétroliers à travers un vaste réseau de stations-services sur toute l’étendue du territoire national.</p>
        <p>Depuis l’obtention de notre agréement de marketeur en 2017, notre réseau de stations-services DISTRICOM OIL ne cesse de s’agrandir.</p>
        <p>Aujourd’hui, nous totalisons plus de quatorze (14) stations-services en Côte d’Ivoire.</p>
        <p>Pour assurer un service continu et être plus proche des consommateurs, toutes nos stations sont ouvertes 7 jours sur 7 et 24 heures sur 24.</p>
    </div>
  </div>
</template>

<script>
export default {
name: 'Districomoil',
}
</script>

<style>

</style>