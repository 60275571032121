<template>
  <div style="background:#eee" class="pb-5">
    <div class="box-img-flyer">
        <img src="../assets/bonon.jpg" alt="">
        <div class="absolute-title text-yellow">
          <div style="width:100%;height:100%;background:rgba(0,0,0,0.6)">
                <h1>Notre groupe !</h1>
                <h1>Découvrez notre compagnie ...</h1>
          </div>
        </div>
    </div>
    <div class="col-sm-12 mt-5">
        <div class="container bg-white p-3">
          <ul class="nav nav-pills border-bottom mb-3 pb-2" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">A propos de nous !</a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Notre force</a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Notre expertise</a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="pills-cont-th" data-toggle="pill" href="#pills-th" role="tab" aria-controls="pills-contact" aria-selected="false">Notre engagement</a>
            </li>
          </ul>
          <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab"><About></About></div>
            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab"><Force></Force></div>
            <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab"><Expertise></Expertise></div>
            <div class="tab-pane fade" id="pills-th" role="tabpanel" aria-labelledby="pills-cont-th"><Engagement></Engagement></div>
          </div>
      </div>
  </div>
  </div>
</template>
<script>
import About from './layouts/about/About.vue'
import Expertise from './layouts/about/Expertise.vue'
import Force from './layouts/about/Force.vue'
import Engagement from './layouts/about/Engagement.vue'
export default {
    components : {
        About,
        Expertise,
        Force,
        Engagement
    }
}
</script>

<style>

</style>