<template>
  <div>
       <div class="box-img-flyer">
        <img src="../assets/slider/bg_01.jpg" alt="">
        <div class="absolute-title text-white">
          <div style="width:100%;height:100%;background:rgba(0,0,0,0.6)">
                <h1>Astuces et conseils !</h1>
                <h1>Adoptons les bons gestes ...</h1>
          </div>
        </div>
       </div>
        <div class="container mt-5 mb-5">
            <h1 class="text-orange">Parcourez nos articles !</h1>
            <p>Soyez les premiers à être informés, prenez de l'avance en restant connectés à nos articles.</p>
            <div class="alert alert-success mt-3" v-if="article">
                <div class="d-flex">
                    <div class="spinner-border mr-5" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>

                    <span>Patientez pendant le chargement de nos articles ...</span>
                </div>
            </div>
            <div class="alert alert-danger" v-if="articlebug">
                Nous n'arrivons pas à nous connecter au serveur, merci de verifier votre connexion internet .. 
            </div>


            <div class="row mt-5">
                <div class="col-sm-4 mb-3" v-for="(item) in data" :key="item.conseil_id">
                    <div class="cadre border p-0">
                        <div style="width:100%;height:200px">
                            <img style="object-fit:cover;width:100%;height:100%" :src="'https://apidistrico.districom-ci.net/public/picture/show/'+item.conseil_image" alt="">
                        </div>
                        <p class="m-0 p-3 text-ellipsis">{{item.conseil_title}}</p>
                        <p class="border-bottom m-0 p-2">
                            <router-link :to="'conseil/post/article/'+item.conseil_id+'/'+replace(item.conseil_title)"  class="btn btn-site font-weight-bold">Lire l'article</router-link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import backendService from '../backend.service';
export default {
data(){
      return {
          data : [],
          article : false,
          articlebug : false,
      }
    },
    mounted(){
            this.article = true;
            this.articlebug = false;
            backendService.conseil_public_get().then((response)=>{
                if(response.status == 200){
                    this.data = response.data.data;
                    this.article = false;
                    this.articlebug = false;
                }
            }).catch(()=>{
                this.article = false;
                this.articlebug = true;
            });
    },
    methods : {
        replace(info){
            let r = info.replace(/ /g,'-');
            r = info.replace(new RegExp("[^(a-zA-Zéèêà)]", "g"),'-').toLowerCase();
            // return r.replace(new RegExp("[^(a-zA-Zé\è\ê\à)]", "g"),'-').toLowerCase();
            return r;
        }
    }
}
</script>

<style>

</style>