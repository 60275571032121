<template>
  <div>
        <div class="container-table" style="background:#26388d">
            <div class="box-cell">
                <div class="col-sm-4 text-center mt-2 border offset-sm-4 p-3" style="background:#FFF;border-radius:6px">
                    <img src="../../../assets/logo.png" style="height:50px" alt="">
                    <h6 class="m-3 text-center font-weight-bold">Compte administrateur</h6>
                    <p class="text-center">Veuillez vous connecter pour accéder à votre compte administrateur</p>
                    <div class="p-3 text-left" style="font-size:12px">
                            <label class="font-weight-bold text-blue" for="">Email ou nom d'utilisateur</label>
                            <input type="email" v-model="email" class="form-control" placeholder="Nom d'utilisateur">
                            <label class="mt-3 font-weight-bold text-blue" for="">Mot de passe</label>
                            <input type="password" v-model="password" class="form-control" placeholder="Mot de passe">

                            <button @click="login" :disabled="!email || !password || loading" type="button" class="btn btn-site font-weight-bold btn-block btn-lg mt-3">Connexion
                                <div v-if="loading" class="spinner-border text-light float-right mt-2" style="height:20px;width:20px" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </button>
                    </div>
                </div>
            </div>
        </div>

  </div>
</template>

<script>
import backendService from '../../../backend.service'
export default {
    name : "Login",
    data(){
        return {
                email : null,
                password : null,
                loading : false,
        }
    },
    computed : {
        takeNext(){
            return this.$route.query.next;
        }
    },
    methods  : {
        login(){
            this.loading = true;
            backendService.signin({
                email : this.email,
                password : this.password
            }).then((response)=>{
                    this.loading = false;
                    localStorage.setItem('districosite__api__Oauth',JSON.stringify(response.data.data));
                    if(this.takeNext) 
                         location.href = this.takeNext;
                    else
                    location.reload(true);
            }).catch(()=>{
                this.loading = false;
            })
        }
    }
}
</script>

<style>

</style>